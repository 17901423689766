.third-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.columns {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.third-step .information-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.third-step .spinner-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}


.third-step .error{
  color: red;
  font-size: 1.8rem;
}

