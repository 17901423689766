.successfulPayment-wrapper {
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reservation-data-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
