@media only screen and (min-width: 768px) {
  /* For desktop: */
  .first-step-form {
    width: 100%;
  }
}

container-step-1 {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.title {
  font-weight: bold;
  color: gray;
}

.select {
  width: 50%;
}

.first-step-form .input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.spinner-container {
  margin-bottom: -15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-picker {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.date-picker > div {
  width: 50%;
}

.time-picker {
  margin-top: 25px;
}

.time-picker .time-picker-title {
  margin: auto;
  text-align: center;
}

.first-step-form span {
  margin-right: 10px;
  margin-top: -5px;
}
