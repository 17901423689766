.stepper-container{
    padding: 20px;
}

.title-label span{
    font-size: 1.1rem;
}

/* global buttons styles */
.button-container button {
    font-size: 1.3rem;
}


