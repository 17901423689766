.second-step,
.second-step-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.second-step-buttons{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

hr {
  width: 100%;
}

.second-step-form .input {
  margin-top: 18px;
}

.second-step-form .input-wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}



