@media only screen and (min-width: 768px) {
  /* For desktop: */
  .reservation-form-container{
    width: 540px;
    padding: 30px;
  }
}



.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 25px;
}


